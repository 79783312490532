<template>
  <div v-if="obj" class="integration-samcart">
    <div class="row">
      <div class="col-md-12">
        <p>Copy the api url below and paste it into Samcart webhook url.</p>
        <div>
          <textarea ref="scriptText" v-model="obj.url" name="code" cols="30" rows="6" class="form-control" readonly
            @click="copyLink"></textarea>
          <div class="text-left mt-1">
            <a href="javascript:void(0)" @click="copyLink">
              Copy the code
            </a>
          </div>
        </div>
      </div>
      <div v-if="isEdit" class="col-md-4 mt-4 d-flex align-items-center">
        <div style="flex: auto">
          <TextInput v-if="!obj.editApiKey" v-model="obj.api_key" name="ApiKey" label="Api Key" :disabled="true" sublabel="(*)" />
          <TextInput v-else v-model="obj.api_key_new" name="ApiKey" label="Api Key" sublabel="(*)" />
        </div>
        <span class="btn btn-primary btn-sm ml-2" @click="cancelEditApiKey">{{ obj.editApiKey ? 'Cancel' : 'Change'
        }}</span>
      </div>
      <div v-else class="col-md-4 mt-4">
        <TextInput v-model="obj.api_key" name="ApiKey" label="Api Key" sublabel="(*)"/>
      </div>
      <div v-if="obj.api_key && !isEdit" class="col-md-12 mb-4">
        <div class="mt-4 pt-2">
          <span class="pr-2" style="font-weight:600;">OFF</span>
          <b-form-checkbox v-model="obj.syncData" name="check-button" switch inline>
            ON - Sync Samcart Data
          </b-form-checkbox>
        </div>
      </div>
      <div v-if="obj.api_key && isEdit" class="col-md-12 mt-4">
        <b-button variant="primary" type="button" :disabled="syncProcessing" @click="clickSyncData">
          <span v-if="syncProcessing">Sync in Process <b-spinner small /></span>
          <span v-else><i class="uil uil-sync"></i> Sync Data</span>
        </b-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import Vue from 'vue'
export default {
  props: {
    obj: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
    }
  },

  computed: {
    syncProcessing() {
      return this.processing || this.obj && !!this.obj.sync_process
    },
  },

  mounted() {
  },

  methods: {
    copyLink() {
      this.$refs.scriptText.select()
      this.$refs.scriptText.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },
    cancelEditApiKey() {
      this.obj.editApiKey = !this.obj.editApiKey
    },
    clickSyncData() {
      this.$emit('syncData', true)
    },
  },
}
</script>
  
<style lang="scss" scoped>
.box-link {
  display: flex;
  justify-content: center;
}

.integration-item {
  border-radius: 5px;
  display: flex;
  align-items: center;

  h3 {
    flex: 1 1 auto;
    font-size: 18px;
  }

  .integration-icon {
    margin-right: 10px;
    max-width: 100px;
  }
}
</style>
  
<style lang="scss">
.integration-page {
  .copy-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      width: 16px;
      color: #5369f8;
    }
  }
}
</style>